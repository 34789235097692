@import '../Footer.config';

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    align-items: center;
    background-color: $gi-white;
    border: 1px solid $gi-white;
    border-radius: 0.3125em;
    color: $gi-black;
    display: flex;
    flex-wrap: no-wrap;
    justify-content: space-between;
    margin-top: 2.25rem;
    padding: 0.75em;
    transition: none;

    &:active,
    &:focus,
    &:hover:not([disabled]) {
      background-color: $gi-white;
      color: $gi-black;
      text-decoration: none;
    }

    @media #{$medium-up} {
      background-color: transparent;
      color: $gi-white;
      margin-top: 0;
    }

    cursor: pointer;
  }
}

.btn {
  align-items: center;
  background-color: $gi-white;
  border: 1px solid $gi-white;
  border-radius: 0.3125em;
  color: $gi-black;
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  margin-top: 2.25rem;
  padding: 0.75em;
  transition: all 0.3s ease;

  &:active,
  &:focus,
  &:hover:not([disabled]) {
    background-color: $gi-white;
    color: $gi-black;
    text-decoration: none;
  }

  @media #{$medium-up} {
    background-color: transparent;
    color: $gi-white;
    margin-top: 0;
  }

  cursor: pointer;
}

.country {
  display: flex;
}

.countryName {
  text-transform: none;
  font-weight: 400;
  font-size: 16px;
}

.countryFlag {
  padding-right: 0.5em;
  margin: 0;

  svg {
    height: 18px;
    width: 29px;
  }
}

.arrowIcon {
  fill: $gi-black;
  height: 18px;
  width: 6px;
  margin-left: 1.25rem;

  @media #{$medium-up} {
    fill: $gi-white;
  }
}

.modalRoot {
  width: 100%;
}
