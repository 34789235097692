@import '../Footer.config';

.minimalFooterContainer {
  clear: both;
}

.minimalFooter {
  background-color: $footer-main-color;
  color: $gi-white;
  padding: 1.5em 3.5em 3.5em;

  a {
    cursor: pointer;
  }
}

.secondary {
  font-size: 0.75rem;
  display: flex;
  flex-flow: row wrap-reverse;
  justify-content: space-between;
  line-height: 1rem;
}

.copyright {
  padding-bottom: 0.5em;

  @media #{$medium-up} {
    margin-right: 1em;
  }
}

.secondaryGroupMinimal {
  margin-bottom: 1em;
}

.secondaryGroupItem {
  color: $gi-white;
  margin-right: 1rem;

  @media #{$medium-up} {
    margin-right: 1.5rem;
  }
}

.secondaryGroupItem:last-of-type {
  margin-right: 0;
}
