@import '../Footer.config';

$footer-large-media-breakpoint: 1150px;
$footer-large-up: media-range($footer-large-media-breakpoint);
$footer-icon-hover-transition: all $complex-duration ease;

.fullFooter {
  clear: both;
}

.mainFooter {
  background-color: $footer-main-color;
  color: $gi-white;
  padding: 3em 1.6em;

  @media #{$medium-up} {
    padding: 4em 5em;
  }
}

.primary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $xlarge-media-breakpoint;

  @media #{$medium-up} {
    justify-content: space-between;
  }
}

.secondary {
  font-size: 0.75rem;
  display: flex;
  flex-flow: row wrap-reverse;
  justify-content: space-between;
  line-height: 1rem;
  border-top: 1px solid $grey-40;
  margin-top: 2.5rem;
  padding-top: 1.5rem;
  max-width: $xlarge-media-breakpoint;

  @media #{$footer-large-up} {
    margin: 4rem auto 0;
  }
}

.link {
  display: block;
  color: $gi-white;
  margin-bottom: 1em;

  &:visited {
    color: $gi-white;
  }
}

.icon {
  &:active,
  &:focus,
  &:hover:not([disabled]) {
    color: $gi-black;
  }
}

.country {
  order: 2;

  @media #{$medium-up} {
    order: 2;
    padding: 1.5em 0 0 6em;
  }

  @media #{$footer-large-up} {
    padding: 1em 0 0;
  }
}

.copyright {
  padding-bottom: 0.5em;
  font-size: 14px;
  font-weight: 200;

  @media #{$medium-up} {
    margin-right: 1em;
  }
}

.secondaryGroup {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  padding-bottom: 0.5em;
  width: 100%;

  @media #{$large-up} {
    width: auto;
  }
}

.primaryGroupHeading {
  background-color: transparent;
  color: $gi-white;
  border: none;
  width: 100%;
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding: 1em 0 1.5em;
  font-size: 16px;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  &:active {
    color: 000;
  }

  @media #{$medium-up} {
    padding-bottom: 2em;
  }

  @media #{$footer-large-up} {
    padding-top: 0;
  }
}

.primaryGroup {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media #{$medium-up} {
    width: auto;
  }
}

.headingIcon {
  @media #{$medium-up} {
    display: none;
  }
}

.items {
  display: none;
  padding: 0 1em;

  @media #{$medium-up} {
    display: block;
    padding: 0;
    min-width: 165px;
    max-width: 184px;
  }

  &.is-active {
    display: block;
  }
}

.activeItems {
  display: block;
}

.socialIcons {
  padding-bottom: 1.5rem;
  margin-top: 1.5em;

  svg {
    fill: $gi-white;
  }
}

.item {
  color: $gi-white;
  margin-right: 1rem;
  font-size: 14px;
  font-weight: 200;

  &:visited {
    color: $gi-white;
  }

  @media #{$medium-up} {
    margin-right: 1.5rem;
  }
}

.item:last-of-type {
  margin-right: 0;
}

.socialIconsLink {
  display: inline-block;
  color: $gi-white;
  margin-right: 0.5rem;
}

.logo {
  order: 3;

  svg {
    fill: $gi-white;
  }

  path {
    transition: $footer-icon-hover-transition;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    path {
      transition: none;
    }
  }

  @media #{$medium-up} {
    flex-direction: row;
    width: 100%;
    display: block;
  }

  @media #{$footer-large-up} {
    flex-direction: column;
    width: auto;
  }
}

.desktopLogo {
  display: none;

  @media #{$medium-up} {
    display: flex;
  }
}

.socialIcon {
  height: 37px;
  width: 36px;

  @media screen and (prefers-reduced-motion: reduce) {
    path {
      transition: none;
    }
  }

  path {
    transition: $footer-icon-hover-transition;
  }

  path:nth-child(2),
  path:nth-child(3),
  circle {
    fill: $footer-main-color;
  }

  &:active {
    path:nth-child(2),
    path:nth-child(3),
    circle {
      fill: $gi-white;
    }

    path:nth-child(1) {
      fill: $gi-black;
    }
  }

  &:focus {
    path:nth-child(2),
    path:nth-child(3),
    circle {
      fill: $gi-white;
    }

    path:nth-child(1) {
      fill: $gi-black;
    }
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    path:nth-child(2),
    path:nth-child(3),
    circle {
      fill: $gi-white;
    }

    path:nth-child(1) {
      fill: $gi-black;
    }
  }
}

.mobileLogo {
  order: 1;

  @media #{$medium-up} {
    display: none;
  }
}

.links {
  font-size: 16px;
  font-weight: 200;
  order: 2;
  padding-top: 1.5em;
  display: flex;

  @media #{$medium-up} {
    flex-direction: row;
    justify-content: space-between;
    order: 3;
    width: 100%;
  }

  @media #{$footer-large-up} {
    padding-top: 0;
    width: 75%;
  }
}
